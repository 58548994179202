@import url('https://fonts.googleapis.com/css2?family=Inika&family=Inter:wght@100..900&family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&family=Istok+Web:ital,wght@0,400;0,700;1,400;1,700&family=Inria+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Varela+Round&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bar {
  /* border: 1px solid red; */
  position: sticky;
  top: 0px;
}

.bar-bar-bar {
  /* margin-right: 450px; */
}

.headings {
  font-size: 40px;
  color: black;
}

.test {
  border: 1px solid red;
}

.testy {
  height: 3000px;
}

.bar-up {
  /* position: sticky;
  top: 0px; */
}

.nav-mask-show {
  -webkit-mask-image: linear-gradient(black 99%, transparent);
  mask-image: linear-gradient(black 99%, transparent);
  mask-position: 0 0px;

}
.nav-mask-hide {
  -webkit-mask-image: linear-gradient(black 50%, transparent);
  mask-image: linear-gradient(black 50%, transparent);
  mask-position: 0 -50vh;  
}

.nav-trans {
  transition: all 0.25s;
  mask-repeat: no-repeat;
}

.inter-love {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

.nav-link:hover {
  color: #CB7AE0 !important;
}

.nav-link {
  font-size: 32px !important;
  font-weight: 300;
  color: rgba(255, 255, 255, 255) !important;
  /* -webkit-text-stroke: 0.2px #CB7AE0; */
  text-shadow:
    -0.5px -0.5px 0 #CB7AE0,
     0   -0.5px 0 #CB7AE0,
     0.5px -0.5px 0 #CB7AE0,
     0.5px  0   0 #CB7AE0,
     0.5px  0.5px 0 #CB7AE0,
     0    0.5px 0 #CB7AE0,
    -0.5px  0.5px 0 #CB7AE0,
    -0.5px  0   0 #CB7AE0;
}

.highlight  {
  color: #CB7AE0 !important;
  -webkit-text-fill-color: #CB7AE0;

}

.inika-regular {
  font-family: "Inika", serif;
  font-weight: 400;
  font-style: normal;
}

.bread {
  width: fit-content;
  margin-bottom: -5px;
}

.breadlet {
  color: #767676;
  margin-left: 8px;
}

.unbutton {
  border: none;
  background-color: transparent;
  margin: 0;
}

ul {
  width: fit-content;
  list-style-type: none;
  /* border: 1px solid red; */

}
li {
  /* border: 1px solid red; */
  width: fit-content;
  margin-left: -32px;
}
li:not(:last-child) {
  margin-bottom: 0px; 
}

.crumbs {
  display: inline-block;
  width: 100%;
}

.jetbrains-mono-yuh {
  font-family: "JetBrains Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.istok-web-regular {
  font-family: "Istok Web", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.varela-round-regular {
  font-family: "Varela Round", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.inria-sans-regular-italic {
  font-family: "Inria Sans", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.inria-sans-regular {
  font-family: "Inria Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.pillcolor {
  background-color: #A09F9F !important
}

.grey-tag {
  background-color:#A09F9F;
  border-radius:20px;
  height:25px;
  width:fit-content;
  font-size:16px;
  color:white;
  padding:0 10px 10px 10px;
}

.purpify {
  background-color:#CB7AE0;
  height:32px;
  padding:3px 10px;
}
.purple {
  background-color:#CB7AE0;
}
.purple-lite {
  background-color:#DB96F3;
}

.nunito-regular {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.filter-button {
  color: #DB96F3;
  transition: all 0.25s;
}

.filter-buttoHov {
  color: white;
  background-color: #DB96F3;
  transform: rotate(45deg);
}

.filter-button:hover {
  color: white;
  background-color: #DB96F3;
  transform: scale(1.1);
}

.filter-buttonV2 {
  color: white;
  transition: all 0.25s;
}

.filter-buttonV2:hover {
  background-color: #DB96F3;
  transform: scale(1.1) rotate(45deg);
  transition: all 0.25s;
}

input:focus {
  outline: none;
}

input {
  border: none;
  border: 1px solid black;
  border-radius: 15px;
  background-color: #EEDCF1;
}

.input-sizing-color {
  height: 35px;
  width: 25px;
  transition: width 0.1s ease-out;
}

input::placeholder {
  color: #725976;
  font-size: 16px;
  font-style: italic;
}

.no {
  visibility:hidden;
}

.garuda-showdown {
  width: 188px;
}

.fontista {
  font-kerning: normal;
  font-variant-numeric: tabular-nums;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.tag-on {
  display: flex;
  gap: 7px;
  flex-wrap: wrap;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 1px solid black;
  display: inline-block;
  margin: 0 5px;
}

.results {
  width: 188px;
  height: 40px;
  text-align: left;
  background-color: white;
  border: 1px solid #E4E4E4;
}

.results:hover {
  background-color: #E4E4E4;
}

a {
  text-decoration: none !important;
  color: black !important;
}

.graphics {
  position: relative;
  /* height: 1200px; */
  border: 1px solid rgba(255, 255, 255, 0);
  background-image: url("assets/vintage-pink-and-cream-dark-floral-plain-820x532\ 3.png");
}

.special-border {
  border: 1px solid white;
}

.modal-button {
  padding: 0;
  position: absolute;
  top: 40px;
  right: -15px;
  border-radius: 15px;
  background-color: #CB7AE0;
  width: 40px !important;
  height: 40px;
}

.modal-div {
  position: sticky;
  top: 50px;
  left: 94vw;
  z-index: 10;
  /* padding: 0; */
  width: fit-content !important;
  height: fit-content !important;
  border: 1px solid rgba(255, 255, 255, 0);
  /* overflow: hidden; */
  /* overflow-y: hidden; */
}

.modal-contents {
  /* position: absolute;
  top: 35px; */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 5px;
  border: 1px solid black;
  /* height: fit-content; */
  /* transform: translateX(100px); */

}

.animatronics {
  visibility: visible;
  transform: translateX(max(-55vw, -190px));
  transition: all 0.25s;
}
.animations {
  transition: all 0.25s;
}

.no-more-display {
  transition: all 0.25s;
  transform: translateX(20vw);
  visibility: hidden;
}

.basic-modal {
  position: absolute;
  top: 35px;
  /* transform: translateX(100px);
  transition: all 0.25s; */


}

.modal-close-button {
  position: absolute;
  top: 5px;
  left: -30px;
}

.modal-window {
  position: absolute;
  height: 50px;
}
.modal-close-shape {
  width: 30px;
  height: 60px;
  padding: 0;
  border-radius: 10px 0 0 10px;
  /* border: 1px solid black; */
  /* border-right-color: white; */
  background-color: #DB96F3;
}

.clear:hover {
  /* color: rgb(255, 142, 142); */
  transform: scale(1.1);
  transition: all 0.05s ease-in-out;
}

.glass-pane {
  background-color: #eaeaeae2;
}

.fonty-smallest {
  font-size: 16px;
}
.fonty-good-size {
  font-size: 24px !important;
}
.fonty-large {
  font-size: 32px;
}

.wrap-text {
  float:left;
  max-width: 100%;
  height: auto;
}

.black-diamonds {
  font-size: 10px;
  vertical-align:middle;
}

.menu-small-seriousness {
  fill: #CB7AE0 !important;
  transform: scale(1.1);
}

.menu-small {
  transition: all 0.15s;
}

.menu-small:hover {
  fill: #DB96F3;
  transform: scale(1.1);
}

.showcase-modal-prime {
  /* min-width: 90%; */
  /* max-height: 10vh; */
  background-color: rgba(255, 0, 0, 0);
  min-width: min(90vw, 900px);

  /* min-width: 820px; */
  /* display: flex; */
  /* align-self: center; */
  /* min-width: 90%; */
  /* max-width: 820px; */


}
.actually {
  /* width: 1820px; */
  /* margin-top: -10px;
  margin-bottom: -10px; */
}

.modal-image {
  width: 100%;
  height: auto;
  margin-top: -10px;
  margin-bottom: -10px;
  /* max-width: 820px; */
  /* max-height: 100vh; */
  /* margin-left: auto; */
}

.up-arrow {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  rotate: 180deg;
  opacity: 0; 
  /* fill: #CB7AE0;  */
}

.down-arrow {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 1;
  opacity: 0;  
  /* fill: #CB7AE0;  */

}
.expand-animation {
  transition: all 0.20s ease-in;
}

.expand-anima-up {
  opacity: 1;  
  transform: translateX(-1rem) translateY(1rem);
}

.expand-anima-down {
  opacity: 1;  
  transform: translateX(-1rem) translateY(1rem);
}

.robert:hover {
  /* background-color: red; */
  background: linear-gradient(to right, rgba(0, 0, 0, 0.08), transparent);
}

.roberta:hover {
  /* background-color: red; */
  background: linear-gradient(to left, rgba(0, 0, 0, 0.08), transparent);
}
.expansionist {
  transition: transform 0.15s;
}
.expansionist:hover {
  transform: scale(1.1);
}

.nav-down {
  transform: translateY(6rem);
}

.nav-down-animation {
  transition: transform 0.15s;
}

.test-block {
  background-color: red;
}

.showcase-image {
  object-position: right top;
}
.special-link {
  background-color: #CB7AE0;
  height: 14px;
  width: 14px;
  border-radius: 7px;
  transition: all 0.15s ease-out;
  z-index: 2;
}

.special-link-animation {
  background-color: #fcb7f4;

  transform: scale(3) translateX(7px);
}

.twofiveeaseinout {
  transition: all 0.20s ease-in-out;
}
.special-link-button {
  visibility: hidden;
  transform: scale(0);
}
.special-link-button-animation {
  visibility: visible !important;
  transform: scale(0.2);
}

.stroketext:hover {
  /* transition: all 0.15s;
  -webkit-text-stroke: 1px navy;
  text-stroke: 4px navy; */
}

.see-all-animation {
  transform: translateX(0px);
  visibility: visible;
}
.see-all {
  visibility: hidden;
  transform: translateX(-55px);
}

.see-all-test{
  transform: translateX(-50px);
}

.see-it-all {
  transition: all 0.15s ease-out;
}

.white-block{
  background-color: white;
  z-index: 1;
  position: relative;
}

.text-outline {
  text-shadow:
    -0.5px -0.5px 0 black,
     0   -0.5px 0 black,
     0.5px -0.5px 0 black,
     0.5px  0   0 black,
     0.5px  0.5px 0 black,
     0    0.5px 0 black,
    -0.5px  0.5px 0 black,
    -0.5px  0   0 black;
}

.footer {
  /* border: 1px solid red; */
  background-color: #f1bbff;
  overflow-y: hidden;
  border-radius: 15px 15px 0 0 ;
}

.footer-foot {
  display: inline-block;
  color:#f1bbff;
  font-size: 128px;
  /* overflow-y: hidden; */
  /* font-weight: 900; */
  /* transform: scaleX(1.2);
  transform-origin: 0 0; */
}

.footer-foot-weight {
    font-weight: 900;
}
.slash {
  /* vertical-align: bottom; */
  margin-top: -20px ;
}

.with-icon {
  white-space:nowrap;
}

.stylin {
  font-weight: 900; 
 transform: scaleX(1.2);
  transform-origin: 0 0;
  margin-top: -10px;
}
.footer-label {
  /* color: #ee99ff; */
}
.link-to{
  font-weight: bold;
  transition: all 0.1s;


}
.link-to:hover img {
  transform: translate(8px,-8px);
}
.link-to:hover {
  font-weight: 900;
}
.animation-fast{
  transition: all 0.1s;
}
.dashed-border {
  /* border-image:  url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
  /* border-left: 3px dashed #000000; */
  background-image: linear-gradient(to bottom, #000000 50%, rgba(255, 255, 255, 0)50%);
  background-position: left;
  background-size: 0.15rem 15px;
  background-repeat: repeat-y;
  /* border: dashed 4px #000; */
  /*optional*/

  /* the initial value of border-style is "none", so it must be set to a different value for the border-image to show*/
  /* border-style: solid; */

  /* Individual border image properties */
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
}

.crumbs-head {
  font-size: min(56px, 14vmin);
  margin-top:-8px;
  display:block; 
  line-height:70px;
}
.no-overflowX{
  /* border: 1px solid red; */
  overflow-x: hidden;
}

.no-wrap{
  white-space:nowrap;

}

.mask-span {
  -webkit-mask-image: linear-gradient(to right, 99%, transparent);
  mask-image: linear-gradient(to right, black 80%, transparent 95%);
  mask-position: 0 0px;
}

.make-exists {
  border: 1px solid rgba(255, 255, 255, 0);
}

.graphics-text{
  min-width: 320px !important;
  max-width: 508px !important;
}
.min-250{
  min-width: 250px;
}

.gutters{
  /* Firefox */
height: -moz-calc(100% - 400px);
/* WebKit */
height: -webkit-calc(100% - 400px);
/* Opera */
height: -o-calc(100% - 400px);
/* Standard */
height: calc(100% - 400px);
}